@import "../../styles/common.scss";

.DefaultLayout {
  width: 100%;
  height: 100%;
  display: flex;
}

.DefaultLayout__NavBar {
  flex-shrink: 0;
  flex-grow: 0;
}

.DefaultLayout__Content {
  height: 100%;
  width: 100%;
}

.DiagramLayout {
  width: 100%;
  height: 100%;
}

.DiagramLayout__Content {
  height: 100%;
  width: 100%;
}

.ModalLayout {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: #ffffff;
}

.ModalLayout__Header {
  height: 52px;
}

.ModalLayout__Content {

}

.menuItemName::first-letter {
  text-transform: uppercase;
}

.menuItemName:global(::first-letter) {
  text-transform: uppercase;
}


.simpleLayout {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  padding: 3.3%;
  display: flex;
  flex-direction: column;
}

.simpleLayoutHeader {
  height: 30px;
  width: 100px;
  margin: 0 auto;
}

.logoWrapper {
  display: block;
  width: 100%;
  height: 100%;
}

.simpleLayoutLogo {
  display: block;
  width: 100%;
  height: 100%;
}

.simpleLayout__Header {
  height: 72px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.simpleLayout__Body {
  width: 100%;
  margin-top: 42px;
  height: 100%;
}

.simpleLayout__Footer {
  display: flex;
  justify-content: center;
}

.columnLayout__Header {
  border-right: 1px solid #e8e8e8;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.menuWrapper {
  margin-top: -4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 64px);
}

.menuTopTitle {
  font-weight: bold;
  white-space: nowrap;
  padding-right: 12px;
  width: 100%;
  word-break: break-all;
  overflow: hidden;
  height: 64px;
  padding-left: 20px;
  text-align: center;
  line-height: 64px;
  text-overflow: ellipsis;
  border-right: 1px solid #e8e8e8;
  border-bottom: 4px solid #e8e8e8;
}
