.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form {
  max-width: 375px;
}

.row {
  min-width: 375px;
  display: flex;
  justify-content: space-between;
}

.form__message {
  text-align: center;
}

.form__errors {
  color: #f5222d;
}
