
@font-face {
  font-family: 'averta';
  src: url('/fonts/extra.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'averta';
  src: url('/fonts/light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'averta';
  src: url('/fonts/regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'averta';
  src: url('/fonts/regular.ttf');
  font-weight: 400;
}

@font-face {
  /* missing medium */
  font-family: 'averta';
  src: url('/fonts/regular.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'averta';
  src: url('/fonts/semibold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'averta';
  src: url('/fonts/bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'averta';
  src: url('/fonts/bold.ttf');
  font-weight: bold;
}

body {
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  min-width: 1220px !important;
}

#root {
  height: 100%;
}

.hidden {
  display: none;
}

.ant-layout-header {
  padding: 0 16px !important;
}

.ant-modal-wrap {
  padding-top: 16px;
  /*background-color: rgba(43, 101, 200, 0.3);*/
}

.ant-modal {
  top: 32px !important;
}

.ant-layout-sider-trigger {
  position: static !important;
}

.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 28px  !important;
}

@media (max-width: 767px) {
  .ant-modal {
     max-width: inherit !important;
  }
}

