@import '../colors';

.modalInfinityProgress :global(.ant-modal-confirm-body) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyList {
  margin: 48px auto;
  text-align: center;
}

.emptyList__title {
  padding: 24px 20%;
  font-weight: 600;
}

.emptyList__description {
  padding: 24px 20%;
}

.apiItem {
  margin-top: 24px;
}

.content {
  margin: 16px;
}

.header {
  background: #ffffff;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  align-items: center;
}

.headerWithSide {
  @extend .header;
  justify-content: space-between;
}

.header__title {
  font-size: 20px;
  max-height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header__panel {

}

.label {
  font-weight: 600;
}


.header__leftSide {
  display: flex;
  align-items: center;
}

.header__rightSide {
  display: flex;
  align-items: center;
}

.header__item__info {
  background-color: #6DBB33;
  color: white;
  font-weight: 600;
  border: none;
}

.header__item__info:hover {
  background-color: rgba(109, 187, 51, 0.74);
}

.header__item {
  margin-right: 8px;
}

.modalList {
  margin-top: 24px;
  height: 300px;
}

.modalItem {
  margin-top: 24px;
}

.panel {
  background: #f7f7f7;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}

.subTitle {
  font-weight: 500;
  min-height: 32px;
}

.formTemplateItem {
  cursor: pointer;

  &:hover {
    background-color: #ececec;
    color: rgba(0, 0, 0, 0.65);
  }
}

.selectedFormTemplateItem {
  background-color: $primaryColor;
  color: #ffffff;
  font-weight: 500;

  &:hover {
    background-color: $primaryColor;
    color: #ffffff;
    font-weight: 500;
  }
}

.contentBody {
  height: calc(100vh - (64px + 69px + 32px));
  overflow-y: auto;
  padding: 24px;
  background: #fff;
  min-height: 360px;
}

.contentBodyWithoutFooter {
  @extend .contentBody;
  height: calc(100vh - (64px + 32px));;
}
